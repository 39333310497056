<template>
  <div>
    <main class="p-3">
      <h2 class="section-title">預選清單</h2>
      <p class="total-quantity">您的預選單共計<span class="total-quantity--emphasis">n</span>項商品</p>
      <div class="products">
        <div
          class="products__item"
          v-for="p in products"
          :key="p.name"
        >
          <img
            :src="`https://picsum.photos/400?${Math.random()}`"
            class="products__item__photo"
          />
          <div class="products__item__title">
            {{ p.title }}
          </div>
          <div class="products__item__price">
            <span class="oprice mr-1" v-if="p.oprice">{{ p.oprice }}</span>
            <span class="price">{{ p.price }}</span>
          </div>
          <div class="products__item__description">
            特潤超導全方位修復露 115 ml
          </div>
          <div class="products__item__quantity">
            <b-form-spinbutton
              size="sm"
              inline
              v-model="p.quantity"
              min="1"
            ></b-form-spinbutton>
          </div>
        </div>
      </div>

      <div class="divider mt-4 mb-1"></div>
      <div class="total-price">TOTAL<span class="total-price--emphasis ml-3 mr-2">6,870</span>元</div>

      <h2 class="section-title" style="margin-top: 64px">個人資料</h2>
      <div class="dynamic-form mt-4">
        <b-input placeholder="姓名"></b-input>
        <b-input placeholder="電話號碼" class="mt-3"></b-input>
        <b-input placeholder="取貨日期" class="mt-3"></b-input>
      </div>
    </main>

    <footer class="sticky-bottom footer-action-bar">
      <b-button
        :to="{ name: 'LiffPreorderProducts' }"
        class="footer-action-bar__checkout-button"
        size="lg"
        variant="secondary"
      >
        返回前頁
      </b-button>
      <b-button
        @click="handleSubmitButtonClicked"
        class="footer-action-bar__checkout-button"
        size="lg"
        variant="primary"
      >
        送出訂單
      </b-button>
    </footer>
  </div>
</template>

<script>
import themeColor from "@/mixins/liff/themeColor";
import deepGet from "lodash/get";

const products = [
  {
    title: "Eco-Friendly Shopping Bag | CHAMOMILE - SIZE S",
    brand: "yaambrand",
    price: "NT$ 375",
    quantity: 1,
  },
  {
    title: "Rex│銀色反光偏光鏡片│墨鏡│太陽眼鏡",
    brand: "墨鏡人生",
    oprice: "NT$ 1,980",
    price: "NT$ 375",
    quantity: 1,
  },
];

export default {
  components: {
  },
  mixins: [themeColor],
  data() {
    return {
      products,
    };
  },
  methods: {
    async handleSubmitButtonClicked() {
      const res = await this.$swal({
        text: '確定要送出訂單嗎？',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
      })

      if (deepGet(res, 'value', false) === true) {
        this.$router.push({ name: 'LiffPreorderFinished' })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.products {
  &__item + &__item {
    margin-top: 16px;
  }

  &__item {
    color: #212121;
    background-color: white;
    text-decoration: none !important;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto 1fr;
    grid-template-areas:
      "photo title"
      "photo price"
      "photo description"
      "photo quantity";

    &__photo {
      grid-area: photo;
      width: clamp(120px, 33vw, 240px);
      min-height: 100%;
      object-fit: cover;
      aspect-ratio: 1 / 1.2;

      @media screen and (min-width: 512px) {
        width: clamp(120px, 25vw, 240px);
      }
    }

    &__title {
      grid-area: title;
      display: -webkit-box;
      margin-top: 16px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      grid-area: price;
      margin-top: 8px;
    }

    &__description {
      grid-area: description;
      display: -webkit-box;
      margin-top: 8px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__quantity {
      grid-area: quantity;
      display: flex;
      margin-top: 8px;
      margin-bottom: 16px;
      align-items: flex-end;

      ::v-deep .b-form-spinbutton {
        height: 36px;
        padding: 0;

        output {
          text-align: center !important;
        }
      }
    }

    &__title, &__price, &__description, &__quantity {
      padding: 0 16px;
    }
  }
}

.section-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: #444;
  text-align: center;
}

.total-quantity {
  color: #777;
  text-align: center;

  &--emphasis {
    margin: 0 8px;
    font-size: 1.25rem;
    font-weight: 500;
    color: #444;
  }
}

.total-price {
  font-size: .875rem;
  color: #444;
  text-align: right;

  &--emphasis {
    font-size: 1.5rem;
  }
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #ccc;
}

.footer-action-bar {
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  box-shadow: 0px -2px 4px 0px #0000000D;

  .btn {
    border-width: 2px;
    padding: 0.8rem;
    font-weight: 500;
    border-radius: 0.5rem;

    &.btn-primary {
      background-color: var(--s-primary);
      border-color: var(--s-primary);
    }
  }

  &__checkout-button {
    flex: 0 1 50%;
  }

  &__checkout-button + &__checkout-button {
    margin-left: 16px;
  }
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
